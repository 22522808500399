import React, {Component} from "react";
import {Box, Grid} from "@mui/material";
import socket from "../../../Socket";
import {decode, formatAmount} from "../../../Helper";
import Countdown from "react-countdown";
import moment from "moment";

class CrasherHourTime extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
        }
    }

    componentDidMount() {
        if (this._isMounted) {
            socket.emit('TOP_CRASHERS_HOUR');
            socket.on('TOP_CRASHERS_HOUR', data => this.setTopPlays(decode(data)));
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setTopPlays(data) {
        this.setState({items: data});
    }

    render() {
        let current_date = moment().format("YYYY-MM-DDTHH");

        const list = this.state.items.map((item, i) =>
            <Items key={i} index={i + 1} item={item}/>
        );

        return (
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={0}>
                    <Grid style={{display: 'inline-flex'}} xs={10}>
                        Hourly {list.length === 0 ? <div>&nbsp;|&nbsp;Loading</div> : list}
                    </Grid>
                    <Countdown zeroPadTime={2} date={current_date + ":59:59"} renderer={renderer}/>
                </Grid>
            </Box>
        );
    }
}

class Items extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {item} = this.props;

        let username = item.name;
        let cashout = item.cashout;
        let result = item.result;

        return (
            <div>
                &nbsp;|&nbsp;{username}:<b className={'text-custom-yellow'}>{formatAmount(cashout, 0)}x</b>
            </div>
        );
    }
}

const renderer = ({formatted}) => {
    return (
        <Grid style={{display: 'inline-flex'}} xs={2}>
            <div className={'top-count-down'}>{formatted.hours}</div>
            :
            <div className={'top-count-down'}>{formatted.minutes}</div>
            :
            <div className={'top-count-down'}>{formatted.seconds}</div>
        </Grid>
    );
};

export default CrasherHourTime;