import React, {Component} from "react";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import InfoIcon from '@mui/icons-material/Info';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LockIcon from "@mui/icons-material/Lock";
import {BRAND, decode, encode, isValidNumber, wait} from "../../../Helper";
import storage from "../../../Storage";
import socket from "../../../Socket";
import C from "../../../Constant";
import {doLogin} from "../../../Functions";

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            phone: '',
            referrer: storage.getKey('referrer') ? storage.getKey('referrer') : null,
            password: '',
            status: false,
            status_state: 'warning',
            disabled: false,
            submitted: false,
            effect: 'pulse'
        };
        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        socket.on(C.REGISTER_USER, data => this.setRegister(decode(data)));
        socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
    }


    setLogin = (data) => {
        doLogin(data);
    };

    setRegister = (data) => {
        let response = data;

        if (response.status === true) {
            this.setState({status: "Successfully Registered. Please Wait...", status_state: 'white', submitted: false});
            wait(1000).then(() => {
                socket.emit(C.LOGIN_USER, encode({
                    phone: this.state.phone,
                    password: this.state.password,
                    recaptcha: 'google'
                }));
            })
        } else {
            this.setState({disabled: false, status: response.status, status_state: 'warning', submitted: false});
        }
    };

    submitForm(e) {
        e.preventDefault();
        const {t} = this.props;

        this.setState({submitted: true, disabled: true, status: "Sending data...", status_state: 'warning'});

        const {username, password, phone, reg, referrer} = this.state;

        if (!(username && password)) {
            this.setState({status: false, status_state: 'warning', disabled: false});
            return;
        }

        if (username.length < 3) {
            this.setState({status: 'Username should be at least 3 characters', status_state: 'warning', disabled: false,});
            return;
        }

        if (phone.length < 9) {
            this.setState({status: 'Please Enter valid phone number.', status_state: 'warning', disabled: false,});
            return;
        }

        if (!isValidNumber(phone)) {
            this.setState({status: 'Please Enter valid phone number', status_state: 'warning'})
        }

        this.setState({username: username, password: password});

        socket.emit(C.REGISTER_USER, encode({
            username: username,
            password: password,
            phone: phone,
            method: reg,
            referrer: referrer
        }));
    }

    render() {
        const {username, password, phone, disabled} = this.state;

        return (
            <>
                {/*<TextField name={'mobile'} type={'number'} fullWidth label="Mobile Number" id="mobile-number" sx={{m: 2}}
                           InputProps={{startAdornment: <InputAdornment position="start"><PhoneIphoneIcon/></InputAdornment>}}/>

                <TextField name={'full-name'} fullWidth label="Your Nickname" id={'nickname'} sx={{m: 2}}
                           InputProps={{startAdornment: <InputAdornment position="start"><PersonPinIcon/></InputAdornment>}}/>*/}
                <form style={{display: 'contents'}} onSubmit={(e) => {
                    this.submitForm(e)
                }}>
                    <div className={'custom-input-outer m-2 w-100'}>
                        <span className={'text-white custom-label'}>Mobile&nbsp;&nbsp;</span>
                        <input required placeholder={'-- e.g. 0722000000 --'} type={"number"} id={"mobile-number"} className={"form-control input-number custom-input"}
                               autoComplete={"off"} name={"username"} onChange={e => this.setState({phone: e.target.value})} value={phone}/>
                        <PhoneAndroidIcon className={'text-white'}/>
                    </div>

                    <div className={'custom-input-outer m-2 w-100'}>
                        <span className={'text-white custom-label'}>Nickname&nbsp;&nbsp;</span>
                        <input required placeholder={'-- e.g. ' + BRAND + ' --'} type={"text"} id={"nickname"} className={"form-control input-number custom-input"}
                               value={username} autoComplete={"off"} onChange={e => this.setState({username: e.target.value})} name={"nickname"}/>
                        <PersonPinIcon className={'text-white'}/>
                    </div>

                    <div className={'custom-input-outer m-2 w-100'}>
                        <span className={'text-white custom-label'}>Password&nbsp;&nbsp;</span>
                        <input required type={"password"} id={"password"} className={"form-control input-number custom-input"} name={"password"}
                               value={password} autoComplete={"off"} onChange={e => this.setState({password: e.target.value})}/>
                        <LockIcon className={'text-white'}/>
                    </div>

                    <div className={'custom-input-outer m-2 w-100 d-none'}>
                        <span className={'text-white custom-label'}>Promo Code&nbsp;&nbsp;</span>
                        <input placeholder={'-- e.g. JL01 --'} type={"text"} id={"promo_code"} className={"form-control custom-input"} autoComplete={"off"} name={"promo_code"}/>
                        <InfoIcon className={'text-white'}/>
                    </div>

                    <Button size={'large'} variant="contained" className={'custom-button m-2'} type={'submit'} fullWidth sx={{m: 2}}><AppRegistrationIcon/>&nbsp;Create
                        Account</Button>
                </form>

                {this.state.status &&
                    <span style={{fontSize: '.7rem'}} className={"text-" + this.state.status_state + " w-100 text-center"}>{this.state.status}</span>
                }

                <div style={{borderTop: '1px solid #80808063'}} className={'col-12 text-center mb-3 mt-3'}>
                    <Link className={'text-white'} style={{textDecoration: 'none', fontSize: '12px'}} to={'/login'}>Already have an account? <span
                        style={{fontWeight: 'bold'}}>Login</span></Link>
                </div>
            </>
        );
    }
}

export default Register;