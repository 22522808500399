import React, {Component} from "react";
import Engine from "../Engine";
import {Event} from "../../../../Helper";
import socket from "../../../../Socket";

class UsersOnline extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            playing_now: 0,
            users_online: 0,
        }
    }

    componentDidMount() {
        if (this._isMounted) {
            const engine = Engine;

            engine.trigger.on("play_crash", data => this.incrementPlaying(data));
            engine.trigger.on("waiting_crash", data => this.resetPlaying(data));

            engine.trigger.on("playing_now", (data) => this.setUsersPlaying(data));
            Event.on("users_online", (data) => this.setUsersOnline(data));
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    incrementPlaying(data) {
        this.setState({playing_now: (this.state.playing_now + 1)});
    }

    resetPlaying(data) {
        this.setState({playing_now: 0});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setUsersOnline(data) {
        this.setState({users_online: data});
    }

    setUsersPlaying(data) {
        this.setState({playing_now: data});
    }

    render() {
        let {users_online, playing_now} = this.state;

        if(users_online === 0) {
            socket.emit('GIVE_ME_ONLINE_USERS');
        }

        return (
            <>
                <td className={'text-center'}>Online: <b>{users_online}</b></td>
                <td className={'text-center'}>Playing: <b>{playing_now}</b></td>
            </>
        );
    }
}

export default UsersOnline;