import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import storage from "../../../../Storage";
import Engine from "../Engine";
import {gameCoin} from "../../../../actions/gameCoin";
import {setWallet} from "../../../../actions/gameWallet";
import {setWinnerText, setMeToGame} from "../../../../actions/crashGame";
import {Event, __, isValidNumber, forceSatoshiFormat, wait, Game, sendNotification, toComma} from "../../../../Helper";
import {alpha, FormControlLabel, Grid, styled, Switch} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {green} from "@mui/material/colors";

const AutoSwitch = styled(Switch)(({theme}) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[600],
        '&:hover': {
            backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[600],
    },
}));

class ManualBet extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: null,
            buttonText: 'READY TO BET',
            buttonType: 'btn-betting-default',
            inputDisabled: false,
            amountDisabled: false,
            buttonProgress: null,
            gameStatus: null,
            clicked: false,
            added: false,
            holding: false,
            payout: storage.getKey('payout') ? storage.getKey('payout') : 1.10,
            amount: storage.getKey('amount') ? storage.getKey('amount') : 10.00,
            target_profit: '',
            winning_chance: '',
            token: storage.getKey('token') ? storage.getKey('token') : null,
            autoRunning: false,
            autoHeader: 'AUTO BET',
            autoBody: 'text-danger',
            autoColor: '#4FCF2F',
            autoCashout: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.setBet = this.setBet.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        if (this._isMounted) {
            const engine = Engine

            this.setState({engine: engine})

            //Load Coin From Redux
            this.props.gameCoin();

            document.addEventListener('mousedown', this.handleClickOutside);

            // Game Event
            engine.trigger.on("game_status", (data) => this.checkStatus(data));

            engine.trigger.on("waiting_crash", () => this.checkWaitingGame());
            engine.trigger.on("busted_crash", () => this.checkBustedGame());
            engine.trigger.on("started_crash", () => this.checkStartedGame());

            // User Event
            engine.trigger.on("play_crash", data => this.handlePlay(data));
            engine.trigger.on("finish_crash", data => this.handleFinish(data));

            //Error
            engine.trigger.on("error_crash", data => this.handleError(data));

            // Stop manual bet
            engine.trigger.on("stop_playing", () => this.stopManual());

            //Events on auto bet
            //engine.trigger.on("auto_bet", data => this.handleAuto(data));
            this.handleCashoutChange = this.handleCashoutChange.bind(this);
            this.handleAutoBetChange = this.handleAutoBetChange.bind(this);
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setTP(payout, amount) {
        let my_target_profit = parseFloat((payout * amount) - amount).toFixed(2);
        if (my_target_profit === 'NaN' || my_target_profit <= 0) {
            my_target_profit = '~';
        } else {
            my_target_profit = toComma(my_target_profit);
        }
        this.setState({target_profit: my_target_profit});
    }

    setWC(payout) {
        let my_wc = parseFloat(99 / payout).toFixed(2);
        if (my_wc === 'NaN' || my_wc <= 0 || my_wc === 'Infinity') {
            my_wc = '~';
        } else {
            my_wc += '%';
        }
        this.setState({winning_chance: my_wc});
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {

            //payout
            let value = parseFloat(this.state.payout);
            if (this.state.payout.length === 0 || value === 'NaN') {
                value = 1.01;
            } else {
                if (value < 1.01) {
                    value = 1.01;
                }

                if (value > 1000000) {
                    value = 1000000;
                }
            }

            this.setState({payout: parseFloat(value).toFixed(2)});

            //amount
            let amount = this.wrapperRef.current.value;

            amount = Math.max(10.00, parseFloat(amount));
            if (amount > 3000) {
                amount = 3000; //maxbet - 3000 | remember to also set in the input
            }

            this.setState({amount})

            if (amount === "NaN") {
                amount = 10.00;
            }
            this.setState({amount: parseFloat(amount).toFixed(2)});

            //set advice values
            this.setTP(value, amount);
            this.setWC(value);
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if (name === 'amount') {
            if (!isValidNumber(value)) return;

            this.setTP(this.state.payout, value);
            storage.setKey("amount", value);
        }

        if (name === 'payout') {
            if (value >= 999999) return;

            this.setTP(value, this.state.amount);
            this.setWC(value);
            storage.setKey("payout", value);
        }

        this.setState({[name]: parseFloat(value)});
    }

    stopManual() {
        let nextText = (this.state.autoRunning) ? 'AUTO BET ON...' : 'READY TO BET';
        this.setState({started: false, buttonText: nextText, inputDisabled: false, amountDisabled: false});
    }

    checkStatus(data) {
        if (this._isMounted) {
            //console.log("game status, data:", data);

            switch (data.status) {
                case 'waiting':
                    this.checkWaitingGame();
                    break;
                case 'started':
                    wait(1000).then(() => {
                        this.checkStartedGame(data);
                    });
                    break;
                case 'busted':
                    this.checkBustedGame();
                    break;
                default:
            }
        }
    }

    handleAuto = (data) => {
        if (this._isMounted) {

            let {amount, payout} = data;

            //console.log("handle auto: st -", this.state.gameStatus, " | data - ", data);

            if (!payout) return alert('payout is empty.');
            if (!amount) return alert('amount is empty.');

            this.setState({amount: amount, payout: payout});

            if (this.state.gameStatus === 'started') {
                this.holdBet();
            } else if (this.state.gameStatus === 'waiting' || this.state.gameStatus === 'busted') {
                this.setBet();
            }
        }
    };

    handleError(data) {
        if (this._isMounted) {
            this.props.setMeToGame(false);

            this.setState({holding: false, added: false, inputDisabled: false, amountDisabled: false});
            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();

            if (!__.isUndefined(data.code)) {
                //if (data.code === 'credit') this.props.setWallet(true, data.uid);
            }

            this.setState({clicked: false})
            sendNotification(data.message, 'error', 'top-center');

            if (!__.isUndefined(data.code)) {
                if (data.code === 'version') {
                    wait(3000).then(() => {
                        window.location.reload();
                    });
                }
            }
        }
    }

    setDefaultButton = () => {
        if (this._isMounted) {
            clearInterval(this.state.buttonProgress);

            let nextText = (this.state.autoRunning) ? 'AUTO BET ON...' : 'READY TO BET';
            this.setState({added: false, holding: false, inputDisabled: false, amountDisabled: false, buttonType: 'btn-betting-default', buttonText: nextText});
        }
    };

    setWaitingButton = () => {
        if (this._isMounted) {

            this.setState({added: true, inputDisabled: true, amountDisabled: true, buttonType: 'btn-betting-wait', buttonText: "BET PLACED..."});
        }
    };

    setOutButton = () => {
        if (this._isMounted) {

            this.setState({inputDisabled: false, amountDisabled: true, buttonType: 'btn-betting-cancel', buttonText: "PLACING BET [Cancel]"});
        }
    };

    setBet() {
        if (this._isMounted) {
            this.setState({clicked: true});
            let {engine, amount, payout, token, autoCashout} = this.state;
            if (autoCashout === false) {
                payout = '';
            }
            engine.coin = this.props.coin;
            engine.token = token;
            engine.amount = amount;
            engine.payout = payout === '' ? '' : parseInt(payout * 100);
            engine.type = 'normal';
            engine.button = 'normal';
            engine.play();
        }
    }

    cashOut() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.finish(Game['current_amount']);
        }
    }

    handlePlay(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                this.props.setMeToGame(true);
            }
        }
    }

    handleFinish(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                clearInterval(this.state.buttonProgress);
                this.props.setWinnerText("  Cashed Out at " + data.current / 100);
                this.props.setMeToGame(false);
                this.setDefaultButton();
            }
        }
    }

    checkWaitingGame() {
        if (this._isMounted) {
            //auto
            this.checkAutoRunning();

            this.props.setWinnerText('');

            this.setState({gameStatus: 'waiting'});

            if (this.state.holding) {
                this.setState({holding: false});
                this.placeBet();
            }
        }
    }

    checkStartedGame(data = null) {
        if (this._isMounted) {
            let {im_in_game} = this.props;
            let {engine} = this.state;

            let coin = engine.coin;
            let amount = engine.amount

            this.setState({gameStatus: 'started'});

            if (data != null) {
                //console.log("check started, players:", data.players.length);
                if (data.players.length > 0) {
                    data.players.forEach((the_player, i) => {
                        if (the_player.uid === 4153751612) {
                            //this.setState({clicked: true});
                        }
                        //console.log("check started, uid:", the_player.uid, ' >>', this.state.clicked);
                    });
                }
            }

            if (im_in_game === true || this.state.clicked) {

                this.setState({
                    inputDisabled: true, amountDisabled: false, buttonType: "btn-betting-cashout", clicked: false
                });

                let counter = 0;
                let self = this;
                this.state.buttonProgress = setInterval(function () {
                    //let calc = amount * (Game['current_amount'] - 1);
                    let current_amount = (Game['current_amount']);
                    if (current_amount >= 1.01) {
                        self.setState({inputDisabled: false});
                    }

                    let calc = amount * current_amount; //Dan
                    self.setState({buttonText: 'CASHOUT ' + forceSatoshiFormat(calc) + '/-'});
                    counter++;
                }
                    .bind(this), 50);
            }
        }
    }

    checkBustedGame() {
        if (this._isMounted) {
            this.setState({gameStatus: 'busted', clicked: false});

            let {im_in_game} = this.props;

            if (!this.state.holding) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }

            if (im_in_game) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }
        }
    }

    isCorrectBet() {
        let {amount, payout} = this.state;

        if (!isValidNumber(amount)) return false;

        if (amount < 10) {
            return false;
        }
        //todo - payout check
        return true;
    }

    placeBet() {
        if (this._isMounted) {
            let {engine} = this.state;

            engine.isHolding = false;
            this.setWaitingButton();
            this.setBet();
        }
    }

    holdBet() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.isHolding = true;
            this.setState({holding: true});
            this.setOutButton();
        }
    }

    handleBet(e) {
        if (this._isMounted) {
            e.preventDefault();

            let {amount, payout, holding, gameStatus, token} = this.state;
            let {im_in_game} = this.props;

            // Check User
            if (!token) {
                return Event.emit('showAuthModal', true);
            }

            // Check is Correct Bet
            if (!this.isCorrectBet()) {
                return false;
            }

            if (amount < 10) {
                return false;
            }

            if (payout !== '') {
                this.setState({payout: (payout * 1).toFixed(2)});
            }

            // Check Game Status to Play
            switch (gameStatus) {
                case 'waiting':
                    this.placeBet();
                    break;

                case 'busted':
                    if (holding) {
                        this.setDefaultButton();
                    } else {
                        this.holdBet();
                    }
                    break;

                case 'started':
                    if (im_in_game) {
                        this.cashOut();
                    } else if (holding) {
                        this.setDefaultButton();
                    } else {
                        this.holdBet();
                    }
                    break;
            }
        }
    }

    setAmount = (e, number) => {
        e.preventDefault();
        this.setState({amount: number});
        storage.setKey("amount", number);
    }

    subAmount = (e) => {
        e.preventDefault();
        let new_amount = parseFloat(this.state.amount) - 10;
        if (new_amount < 10) {
            new_amount = 10;
        }
        this.setState({amount: new_amount});
        storage.setKey("amount", new_amount);
    }

    addAmount = (e) => {
        e.preventDefault();
        let new_amount = parseFloat(this.state.amount) + 10;
        if (new_amount > 3000) {
            new_amount = 3000;
        }
        this.setState({amount: new_amount});
        storage.setKey("amount", new_amount);
    }

    subPayout = (e) => {
        e.preventDefault();
        let new_payout = parseFloat(this.state.payout) - 1;
        if (new_payout < 1.01) {
            new_payout = 1.01;
        }
        this.setState({payout: new_payout});
        storage.setKey("payout", new_payout);
    }

    addPayout = (e) => {
        e.preventDefault();
        let new_payout = parseFloat(this.state.payout) + 1;
        if (new_payout > 999999) {
            new_payout = 999999;
        }
        this.setState({payout: new_payout});
        storage.setKey("payout", new_payout);
    }

    runMyAuto = (e) => {
        if (this.state.autoRunning) {
            this.setState({autoRunning: false, autoHeader: 'AUTO BET', autoBody: 'text-danger', autoColor: '#4FCF2F', buttonText: 'READY TO BET'});
        } else {
            this.setState({autoRunning: true, autoHeader: 'RUNNING!', autoBody: 'mdi mdi-refresh text-danger my-spinner', autoColor: 'chartreuse', buttonText: 'AUTO BET ON...'});
        }
    }

    checkAutoRunning = () => {
        if (this.state.autoRunning) {
            this.setWaitingButton();
            let amount = this.state.amount;
            let payout = this.state.payout;

            this.handleAuto({amount, payout});
        }
    }

    handleCashoutChange(event) {
        let checked = event.target.checked;
        this.setState({autoCashout: checked});
    }

    handleAutoBetChange(event) {
        let checked = event.target.checked;
        if (checked) {
            this.setState({autoRunning: true, autoHeader: 'RUNNING!', autoBody: 'mdi mdi-refresh text-danger my-spinner', autoColor: 'chartreuse', buttonText: 'AUTO BET ON...'});
        } else {
            this.setState({autoRunning: false, autoHeader: 'AUTO BET', autoBody: 'text-danger', autoColor: '#4FCF2F', buttonText: 'READY TO BET'});
        }
    }

    render() {
        let {
            amount,
            inputDisabled,
            amountDisabled,
            payout,
            buttonType,
            buttonText,
            autoCashout
        } = this.state;

        //initial render
        if (amount === "NaN") {
            amount = 10.00;
        }

        //Auto
        let payout_disabled = !autoCashout;
        let payout_text = autoCashout ? "[@" + payout + "x]" : "";

        return (<div>
            <form className="w-100" onSubmit={(e) => {
                this.handleBet(e);
            }}>

                <Grid className={'pt-1'} container spacing={1}>
                    <Grid direction="row" justifyContent="center" alignItems="center" item xs={'auto'}>
                        <div className={'bet-amounts p-1'}>
                            <h6 className={'text-center p-0 m-0'}>Stake [KES]</h6>
                            <div className={'bet-area'}>
                                <RemoveCircleIcon className={'sub-add-amount'} onClick={e => this.subAmount(e)}/>
                                <input ref={this.wrapperRef} disabled={amountDisabled} type={"number"} id={"spinner"}
                                       className={"text-strong form-control input-number text-center"}
                                       min={10} value={amount} name={"amount"} autoComplete={"off"} step={0.01}
                                       aria-live={"polite"} tabIndex={"-1"} aria-labelledby={"label-text"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange}/>
                                <AddCircleIcon className={'sub-add-amount'} onClick={e => this.addAmount(e)}/>
                            </div>


                            <table style={{width: '100%'}}>
                                <tr>
                                    <td align={'center'}>
                                        <button className={'btn btn-secondary btn-sm btn-amounts'} onClick={e => this.setAmount(e, 50)}>50</button>
                                    </td>
                                    <td align={'center'}>
                                        <button className={'btn btn-secondary btn-sm btn-amounts'} onClick={e => this.setAmount(e, 100)}>100</button>
                                    </td>
                                    <td align={'center'}>
                                        <button className={'btn btn-secondary btn-sm btn-amounts'} onClick={e => this.setAmount(e, 200)}>200</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td align={'center'}>
                                        <button className={'btn btn-secondary btn-sm btn-amounts'} onClick={e => this.setAmount(e, 300)}>300</button>
                                    </td>
                                    <td align={'center'}>
                                        <button className={'btn btn-secondary btn-sm btn-amounts'} onClick={e => this.setAmount(e, 500)}>500</button>
                                    </td>
                                    <td align={'center'}>
                                        <button className={'btn btn-secondary btn-sm btn-amounts'} onClick={e => this.setAmount(e, 3000)}>3000</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <button name={'bet'} className={"btn btn-betting " + buttonType} disabled={inputDisabled} type={"submit"}>
                            <span className={"d-flex flex-column justify-content-center align-items-center"}>
                                <span style={{alignItems: 'center', display: 'inline-flex'}}>{buttonText}</span>
                                <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>{amount} <span style={{fontSize: '0.8rem'}}>KES&nbsp;{payout_text}</span></span>
                            </span>
                        </button>
                    </Grid>
                </Grid>

                <div className={'row mt-2'}>
                    <div className={'col-12'}>
                        <div style={{width: '100%'}} className={'bet-area'}>
                            <FormControlLabel className={'mx-2'} name={"auto_cashout"} control={
                                <AutoSwitch onChange={this.handleCashoutChange} size={'medium'}/>
                            } label={"Auto Cashout"} labelPlacement="start"/>

                            <div style={{display: 'inline-flex', alignItems: 'center', marginRight: 'auto'}}>
                                <RemoveCircleIcon className={'sub-add-amount'} onClick={e => this.subPayout(e)}/>
                                <input disabled={payout_disabled} style={{width: '3.5rem'}} type={"number"} id="spinner"
                                       className="text-strong form-control input-number text-center"
                                       name="payout" min={1.01} max={99999} value={payout} aria-live="polite" tabIndex="-1" aria-labelledby="label-text" step={0.01}
                                       autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange}/>
                                <AddCircleIcon className={'sub-add-amount'} onClick={e => this.addPayout(e)}/>
                            </div>

                            <FormControlLabel value={"auto"} control={<AutoSwitch onChange={this.handleAutoBetChange} size={'medium'}/>} label="Auto Bet" labelPlacement="start"/>
                        </div>
                    </div>
                </div>
            </form>
        </div>);
    }
}

ManualBet.propTypes = {
    coin: PropTypes.string, im_in_game: PropTypes.bool, credit: PropTypes.number
};

const mapStateToProps = state => ({
    coin: state.items.coin, im_in_game: state.items.im_in_game, credit: state.items.credit
});

export default connect(mapStateToProps, {gameCoin, setWinnerText, setMeToGame, setWallet})(ManualBet);