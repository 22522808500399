import React from "react";
import Footer from "../Parts/Footer";
import {Row} from "reactstrap";
import {decode, LOGO_MOBILE, LOGO_WIDTH, wait} from "../../../Helper";
import {Box, Paper} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import {Link} from "react-router-dom";
import socket from "../../../Socket";
import C from "../../../Constant";
import Cookies from "js-cookie";
import storage from "../../../Storage";

class AuthTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        socket.on(C.ONLINE, (status) => this.loginUser(decode(status)));
    }

    loginUser = (data) => {
        wait(500).then(() => {
            if (data.status === true) {

                Cookies.set("uid", data.id, {expires: 14});
                Cookies.set("auth", true, {expires: 14});

                storage.setKey('name', data.name);
                storage.setKey('email', data.email);
                storage.setKey('phone', data.phone);
                storage.setKey('avatar', data.avatar);
                storage.setKey('friends', data.friends);
                storage.setKey('room', data.room);
                storage.setKey('user_id', data.user_id);

                if (data.identifier === 'from login page') {
                    //window.location.replace('/')
                    this.props.navigate('/', { replace: true });
                }

            } else {
                wait(700).then(() => {
                    localStorage.clear();
                })
            }
        })
    }

    render() {
        let {content, t} = this.props;

        return (
            <div id="content" className={"pt-4 pb-1 animated fadeIn custom-panel-dark"}>
                <div style={{margin: 'auto'}} className={'col-11 col-lg-4 mb-4'}>
                    <Link to={'/'}><HomeIcon fontSize={'large'} sx={{color: 'whitesmoke'}}/></Link>
                </div>
                <Paper className={'col-11 col-lg-4 mb-4'} elevation={24} sx={{margin: 'auto', backgroundColor: '#00000040'}}>
                    <Row>
                        <div className={'col-12 text-center'}>
                            <img style={{width: LOGO_WIDTH}} src={LOGO_MOBILE} className={"mt-2"} alt={"..."}/>
                        </div>
                    </Row>
                    <hr style={{backgroundColor: 'whitesmoke'}}/>
                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                        {content}
                    </Box>
                </Paper>
                <Footer t={t}/>
            </div>
        )
    }
}

export default AuthTemplate;