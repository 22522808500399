import React from 'react';
import {isMobile, wait, Event, BORDER_RADIUS, BANNER_HOUR} from "../../../Helper";
import storage from "../../../Storage";
import Engine from "./Engine";
import Canvas from "./Graphic";
import MobileCanvas from "./Graphic/Mobile";
import HistoryList from "./includes/HistoryList";
import Bet from "./Bet";
import PlayersTable from "./PlayersTable";
import LowerPane from "./LowerPane";
import {Row} from "reactstrap";
import {Link} from "react-router-dom";
import UsersOnline from "./includes/UsersOnline";
import Paper from "@mui/material/Paper";
import CrasherHour from "../../Pages/Promo/CrasherHour";
import CrasherHourTime from "../../Pages/Promo/CrasherHourTime";
import ReferDrawer from "../../Pages/Affiliate/ReferDrawer";

class Index extends React.Component {
    _Mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            height: null,
            mobile: false,
            isLogged: false,
            token: false,
            padding: "p-1",
            connection_status: 'green'
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        if (storage.getKey('token')) {
            this.setState({isLogged: true, token: storage.getKey('token')});
        }

        wait(500).then(() => {
            Engine.getStatus();
        });

        Event.on("connect", (data) => this.onlineStatus(data));
        Event.on("disconnect", (data) => this.offlineStatus(data));
    }

    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;
    }

    handleResize() {
        if (this.getWidth() < 1540) {
            this.setState({col: 'col-xl-12'});
            Event.emit('hide_games');
        } else {
            this.setState({col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if (isMobile()) {
            this.setState({mobile: true});
        } else {
            this.setState({mobile: false});
        }

        if (isMobile()) {
            this.setState({padding: 'p-0', ovh: 'ovh'});
        }
    }

    getWidth() {
        return document.documentElement.clientWidth || document.body.clientWidth;
    }

    onlineStatus = (data) => {
        this.setState({connection_status: 'green'});
    }

    offlineStatus = (data) => {
        this.setState({connection_status: 'red'});
    }

    showBannerHour = () => {
        Event.emit('show_banner_hour');
    }

    render() {
        let token = storage.getKey('token');
        let {mobile, connection_status} = this.state;
        const {t} = this.props;
        let right_border = mobile ? 'none' : '1px solid #213743';

        return (
            <span style={{width: "inherit"}}>
                <div>
                    {this._Mounted ?

                        <div className="row">
                            <div className="col-md-9">
                                <div className="card custom-panel-dark" style={{
                                    minHeight: "300px",
                                    height: '100%',
                                    borderRadius: BORDER_RADIUS,
                                    border: 0
                                }}>

                                    <Row>
                                        <CrasherHour/>
                                        <div onClick={this.showBannerHour} className={"col-12 mb-1"}>

                                            <img className={"d-block w-100"} style={{borderRadius: BORDER_RADIUS + ' ' + BORDER_RADIUS + ' 0 0'}} src={BANNER_HOUR}
                                                 alt="Crasher Hour"/>
                                            <div className={'w-100 custom-panel-light'}
                                                 style={{borderRadius: '0 0 ' + BORDER_RADIUS + ' ' + BORDER_RADIUS, fontSize: '0.8em', paddingLeft: '5px'}}>
                                                <CrasherHourTime/>
                                            </div>
                                        </div>
                                    </Row>

                                    {/*start game*/}

                                    <div className={"row"}>
                                        <div className={"col-md-7"} style={{borderRight: right_border}}>
                                            <Paper elevation={6} className={"p-1 game-aria text-center no-shadow"}>
                                                <HistoryList game={'crash'} t={this.props.t}/>

                                                {!mobile ? <Canvas mobile={this.state.mobile}/> : <MobileCanvas mobile={this.state.mobile}/>}
                                            </Paper>
                                        </div>
                                        <div className={"col-md-5"}>
                                            {(token !== null) &&
                                                <Bet mobile={this.state.mobile} token={this.state.token}
                                                     isLogged={this.state.isLogged}/>
                                            }
                                            {(token === null) &&
                                                <Row>
                                                    <div className={'col-12 text-center'}>
                                                        <Link className={'custom-card m-2 text-custom-yellow custom-border-color'}
                                                              style={{textDecoration: 'none', fontSize: '1.1rem'}} to={'/login'}>
                                                            Login to Play and START WINNING
                                                        </Link>
                                                    </div>
                                                </Row>
                                            }
                                        </div>
                                    </div>

                                    <Row>
                                        <div className={"col-12"}>
                                            <ReferDrawer/>
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className={"col-12"}>
                                            <div className={"p-1 mb-0 custom-panel-dark"}>
                                                <table style={{width: '50%', margin: 'auto', fontSize: '10px', color: 'white'}}>
                                                    <tr>
                                                        <td align={'right'}>Network:&nbsp;</td>
                                                        <td>
                                                            <div style={{width: '12px', height: '12px', borderRadius: '50%', backgroundColor: connection_status}}></div>
                                                        </td>
                                                        <UsersOnline/>
                                                    </tr>
                                                </table>

                                                <LowerPane t={this.props.t}/>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                            <div className="d-none d-md-block col-md-3">
                                <div className="card custom-panel-dark p-2" style={{
                                    minHeight: "300px",
                                    height: '100%',
                                    borderRadius: BORDER_RADIUS
                                }}>
                                    <PlayersTable t={this.props.t}/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="game-loader text-center">
                            <div className={"spinner-grow text-info my-3"} role="status"/>
                        </div>
                    }
                </div>
            </span>
        );
    }

    showLogin() {
        Event.emit('show_login');
    }

    showRegister() {
        Event.emit('show_register');
    }
}

export default Index;