import React from "react";
import {Dropdown} from "react-bootstrap";
import storage from "../../../../Storage";
import coins from "../../../coins";
import socket from "../../../../Socket";
import {__, isMobile, encode, decode, getElement, wait, forceSatoshiFormat, sendNotification, Event, COIN_TOKEN, formatAmount} from "../../../../Helper";
import DepositArchive from "./DepositArchive";
import C from "../../../../Constant";
import Cookies from "js-cookie";

class Deposit extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            //uid: getUID(),
            uid: Cookies.get('uid'),
            currentCoin: COIN_TOKEN,
            loading: true,
            credits: {},
            list: [],
            content: [],
            wallet: 'Please Wait...',
            deposit: true,
            slide: false,
            height: 573,
            margin: 'mt-1',
            amount: 100,
            phone: storage.getKey('phone'),
            name: storage.getKey('name')
        };
    }

    componentDidMount() {
        this._isMounted = true;
        socket.emit(C.CREDIT, encode({token: this.state.token, coin: this.state.currentCoin}));
        socket.on(C.CREDIT, data => this.getCreditAndCoins(decode(data)));
        socket.on(C.UPDATE_PAYMENT_STATUS, (data) => this.paymentUpdate(decode(data)));

        wait(400).then(() => {
            this.loadAddress(true, this.state.currentCoin);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    paymentUpdate(data) {
        if (this._isMounted) {
            let {token, uid} = this.state;

            if (uid !== null) {
                if (parseFloat(data.uid) === parseFloat(uid)) {
                    sendNotification(data.message, 'success', 'top-right');
                    Event.emit('update_payment');
                    this.setState({submitted: false});
                }
            }

            wait(1000).then(() => {
                if (token !== null)
                    socket.emit(C.CREDIT, encode({token: token, coin: data.coin}));
            })
        }
    }

    getCreditAndCoins = (data) => {
        if (this._isMounted) {
            let {credit} = data;
            this.setState({credits: credit, loading: false});
            this.setUpCoins();
        }
    };

    getFee = (coin) => {
        coin = __.upperCase(coin);

        let min;
        coins.forEach((item, key) => {
            if (item.preffix === coin)
                min = item.min;
        })

        return min;
    }

    setUpCoins = () => {
        if (isMobile()) {
            this.setState({height: 685, margin: 'mt-1'});
        }

        coins.forEach((item, key) => {
            let credit = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
            let list = <Dropdown.Item as={'button'} className={"animated fadeIn"} onClick={(e) => this.loadAddress(item.active, item.preffix)}>
                <span><img src={"/assets/images/" + item.image} className={"img-fluid mini-coin mr-1"} alt="Coin"/> {item.preffix}</span>
            </Dropdown.Item>;
            this.setState(state => ({list: [list, ...state.list]}));
        });
    };

    showArchive = e => {
        this.setState({slide: !this.state.slide});
        wait(500).then(() => {
            this.setState({deposit: !this.state.deposit});
        })
        wait(500).then(() => {
            this.loadAddress(true, this.state.currentCoin);
        })
    };

    loadAddress(active, coin) {
        if (this._isMounted) {
            if (active)
                socket.emit(C.GET_ADDRESS, encode({coin: this.state.currentCoin, token: this.state.token}));
            else {
                getElement('#' + __.upperCase(coin)).value = `${coin} not Supported yet`;
            }
        }
    }

    submit = (e) => {
        e.preventDefault();

        socket.emit(C.DEPOSIT, encode({
            phone: this.state.phone,
            amount: this.state.amount,
        }));

        wait(1000).then(() => {
            this.setState({submitted: true});
        })
    }

    handleChangeAmount = (e) => {
        let val = e.target.value;
        this.setState({amount: val});
    }

    render() {
        let style;
        let size = 'w-25';
        let demo_credit = parseFloat(this.state.credits['demo']).toFixed(2);
        let bonus_credit = parseFloat(this.state.credits['bonus']).toFixed(2);

        if (isMobile()) {
            style = "col-2";
            size = 'w-50'
        }

        return (
            <>
                {
                    this.state.deposit ?
                        <div className={this.state.slide ? 'animated slideOutLeft ' : ' font-14'}>
                            {this.state.loading ?
                                <div className="ycenter text-center">
                                    <div class="spinner-border text-info" role="status"/>
                                </div>
                                :
                                <>
                                    <div className="lrow text-center text-white p-1 ycenter">
                                        <div className={"p-1 mr-1"}>
                                            <span className={'text-success'}>Balance</span><br/>
                                            <span className="text-white font-15">{formatAmount(this.state.credits[this.state.currentCoin])}</span>
                                        </div>
                                        <div style={{borderRight: '1px solid yellow', borderLeft: '1px solid yellow'}} className={"p-1 mr-1"}>
                                            <span className={'text-success'}>Bonus</span><br/>
                                            <span className="text-white font-15">{formatAmount(bonus_credit)}</span><br/>
                                            <a className={'text-black btn btn-sm btn-custom'} href={'/bonus'}>Redeem Bonus</a>
                                        </div>
                                        <div className={"p-1"}>
                                            <span className={'text-success'}>Free Bet</span><br/>
                                            <span className="text-white font-15">{formatAmount(demo_credit)}</span>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className={"row"}>
                                        <div className={"col-6"}>
                                            <div className="form-group text-center">
                                                <p className="text-white text-left">
                                                    Enter Amount [Min. 10]
                                                </p>
                                                {
                                                    this.state.submitted ?
                                                        <span className="text-white"><div className="spinner-border spinner-border-sm text-white" role="status"/><br/>Please Enter your PIN in the prompt on your Phone</span>
                                                        :
                                                        <form onSubmit={(e) => {
                                                            this.submit(e)
                                                        }}>
                                                            <input type="hidden" value={this.state.phone} name="phone"/>
                                                            <div className="form-group text-left">
                                                                <input type="number" name={'amount'} className={'form-control'} min={10}
                                                                       style={{background: "white", color: 'black'}} value={this.state.amount} onChange={this.handleChangeAmount}
                                                                       required={true} autoComplete={'off'}/>
                                                            </div>
                                                            <button type="submit" className="btn btn-block bg-cs2">Lipa Na M-PESA Online</button>
                                                        </form>
                                                }
                                                <br/>
                                                <div onClick={this.showArchive} className={'text-record font-12'}>Previous Deposits</div>
                                            </div>
                                        </div>
                                        <div className={"col-6 border-left"}>
                                                <span style={{textAlign: "left", color: 'white', fontSize: '11px'}}>
                                                    &#10004; Please use STK option to deposit.<br/>
                                                    &#10004; Manual deposits might not be supported in the future.<br/>
                                                    &#10004; Enter the amount and Click <strong style={{color: 'red'}}>Submit</strong>.<br/>
                                                    &#10004; Enter your <strong style={{color: 'red'}}>PIN</strong> on your phone when prompted.
                                                </span>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        :
                        <>
                            <div className={this.state.slide == false ? 'animated slideOutLeft' : ''}>
                                <DepositArchive clicked={this.showArchive}/>
                            </div>
                        </>
                }
            </>
        );
    }
}

export default Deposit;