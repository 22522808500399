import React from 'react';
import {Box, Drawer} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Link} from "react-router-dom";
import {BORDER_RADIUS} from "../../../../Helper";

export default function UserAgreements() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <div>
            <p style={{fontSize: '0.8rem', fontWeight: 'bold', color: '#ff9b16'}} className={'m-0'} onClick={toggleDrawer(true)}>Terms & Conditions</p>

            <Drawer PaperProps={{sx: {background: 'transparent', marginTop: '30px', height: '92%'}}} anchor={'right'} open={open} onClose={toggleDrawer(false)}>

                <div style={{position: 'fixed'}} className={'container-fluid'}>
                    <div className={'row'}>
                        <div style={{textAlign: 'right'}} className={'col'}>
                            <HighlightOffIcon onClick={toggleDrawer(false)} fontSize={'large'} sx={{color: 'red'}}/>
                        </div>
                    </div>
                </div>

                <Box className={'m-2'} style={{background: 'antiquewhite', borderRadius: BORDER_RADIUS + ' 0'}} component={"main"} sx={{ flexGrow: 1, p: 3 }}>
                    1. GENERAL
                    <br/>
                    1.1. This User Agreement applies to the usage of games accessible through alfabet.ke, alfabet.co.ke, and alfacrash.com and offered by Dunamis
                    Entertainment Limited t/a Alfabet ("The Company", "us", "we", "our").
                    <br/>
                    1.2. This User Agreement comes into force as soon as you complete the registration process, which includes checking the box accepting these User
                    Agreements and successfully creating an account. By using any part of the Website following account creation, you agree to these User Agreements.
                    <br/>
                    1.3. You must read these User Agreements carefully in their entirety before creating an account. If you do not agree with any provision of these User
                    Agreements, you must not create an account or continue to use the Website.
                    <br/>
                    1.4. The Company is entitled to make amendments to these User Agreements at any time and without advanced notice. If we make such amendments, we may
                    take appropriate steps to bring such changes to your attention (such as by SMS or placing a notice in a prominent position on the Website, together
                    with the amended User Agreement) but it shall be your sole responsibility to check for any amendments, updates, and/or modifications. Your continued
                    use of our site services and Website after any such amendment to the User Agreement will be deemed as your acceptance and agreement to be bound by
                    such amendments, updates, and/or modifications.
                    <br/>
                    1.5. These User Agreements may be published in several languages for informational purposes and ease of access by players. The English version is the
                    only legal basis of the relationship between you and The Company and in the case of any discrepancy with respect to a translation of any kind, the
                    English version of these User Agreements shall prevail.
                    <br/>

                    2. BINDING DECLARATIONS
                    <br/>
                    2.1. By agreeing to be bound by these User Agreements, you also agree to be bound by our site Rules and Privacy Policy that are hereby incorporated by
                    reference into these User Agreements. In the event of any inconsistency, these User Agreements will prevail. You hereby represent and warrant that:
                    <br/>
                    2.2. You are over (a) 18 Years of Age or (b) such other legal age or age of majority as determined by any laws which are applicable to you, whichever
                    age is greater.
                    <br/>
                    2.3. You have full capacity to enter into a legally binding agreement with The Company and you are not restricted by any form of limited legal
                    capacity.
                    <br/>
                    2.4. All information that you provide to The Company during the term of validity of this agreement is true, complete, correct, and that you shall
                    immediately notify The Company of any change of such information.
                    <br/>
                    2.5. You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings that you receive
                    from us.
                    <br/>
                    2.6. You understand that by using our services you take the risk of losing money deposited into your Wallet and accept that you are fully and solely
                    responsible for any such loss.
                    <br/>
                    2.7. You are permitted in the jurisdiction in which you are located to use online gaming services.
                    <br/>
                    2.8. In relation to Deposits and Withdrawals of funds into and from your Wallet, you shall only use legal tender that is valid and lawfully belongs to
                    you and accepted in Kenya.
                    <br/>
                    2.9. The computer software, the computer graphics, the Websites, and the user interface that we make available to you is owned by our site or its
                    associates and is protected by copyright laws. You may only use the software for your own personal, recreational uses in accordance with all rules,
                    User Agreements we have established, and in accordance with all applicable laws, rules, and regulations.
                    <br/>
                    2.10. You affirm that you are not an officer, director, employee, consultant, or agent of our site or working for any company related to our site, or
                    a relative or spouse of any of the foregoing.
                    <br/>
                    2.11. You are not diagnosed or classified as a compulsive or problem gambler. The Company is not accountable if such problem gambling arises while
                    using our services, but will endeavor to inform you of relevant assistance available. The Company reserves the right to implement cool-off periods if
                    we believe such actions will be of benefit.
                    <br/>
                    2.12. You accept and acknowledge that we reserve the right to detect and prevent the use of prohibited techniques, including but not limited to
                    fraudulent transaction detection, modification of bet amount limits, automated registration and signup, gameplay and screen capture techniques. These
                    steps may include, but are not limited to, examination of Players' device properties, detection of geo-location and IP masking, and transaction
                    analysis. Any fraudulent activities may lead to suspension of your account and legal action as stipulated in Cyber Crime Acts in governing laws.
                    <br/>
                    2.13. You accept our right to terminate and/or change any games or events being offered on the Website, and to refuse and/or limit bets.
                    <br/>
                    2.14. You accept that we have the right to ban/block multiple accounts and freely control the assets in such accounts.
                    <br/>

                    3. RESTRICTED TERRITORIES
                    <br/>
                    3.1. The game is open to players within Kenya and who are registered with Mobile Network Companies (MNOs) within Kenya.
                    <br/>

                    4. GENERAL BETTING RULES
                    <br/>
                    4.1. A bet can only be placed by a registered account holder.
                    <br/>
                    4.2. A bet can only be placed over any one of the allowed access channels inlcuding SMS, USSD and the internet.
                    <br/>
                    4.3. You can only place a bet if you have a sufficient amount in your account with our site.
                    <br/>
                    4.4. The bet, once concluded, will be governed by the version of the User Agreement valid and available on the Website at the time of the bet being
                    accepted.
                    <br/>
                    4.5. Any payout of a winning bet is credited to your account, consisting of the stake multiplied by the odds at which the bet was placed.
                    <br/>
                    4.6. Our site reserves the right to adjust a bet payout credited to our site account if it is determined by our site in its sole discretion that such
                    a payout has been credited due to an error.
                    <br/>
                    4.7. A bet, which has been placed and accepted, cannot be amended, withdrawn, or canceled by you but will be subject to bet limits checks.
                    <br/>
                    4.8. The list of all the bets, their status, and details are available to you on the Website.
                    <br/>
                    4.9. When you place a bet, you acknowledge that you have read and understood in full all of these User Agreements regarding the bet as stated on the
                    Website.
                    <br/>
                    4.10. Our site manages your account, calculates the available funds, the pending funds, the betting funds as well as the amount of winnings. Unless
                    proven otherwise, these amounts are considered as final and are deemed to be accurate.
                    <br/>
                    4.11. You are fully responsible for the bets placed.
                    <br/>
                    4.12. Winnings will be paid into your account after the final result is confirmed.
                    <br/>

                    5. BONUSES AND PROMOTIONS
                    <br/>
                    5.1. Our site reserves the right to cancel any promotion, bonus, or bonus program (including, but not limited to top-up rewards, invite friends to
                    reward bonuses, and loyalty programs) with immediate effect if we believe the bonus has been set up incorrectly or is being abused, and if the said
                    bonus has been paid out, we reserve the right to decline any Withdrawal request and to deduct such amount from your account. Whether or not a bonus is
                    deemed to be set up incorrectly or abused shall be determined solely by our site.
                    <br/>
                    5.2. If you use a Deposit Bonus, no Withdrawal of your original deposit will be accepted before you have reached the requirements stipulated under the
                    User Agreement of the Deposit Bonus.
                    <br/>
                    5.3. Where any term of the offer or promotion is breached or there is any evidence of a series of bets placed by a customer or group of customers,
                    which due to a deposit bonus, enhanced payments, free bets, risk-free bets, or any other promotional offer results in guaranteed customer profits
                    irrespective of the outcome, whether individually or as part of a group, our site reserves the right to reclaim the bonus element of such offers and
                    in their absolute discretion either settle bets at the correct odds, void the free bet bonus and risk-free bets or void any bet funded by the deposit
                    bonus. In addition, our site reserves the right to levy an administration charge on the customer up to the value of the deposit bonus, free bet bonus,
                    risk-free bet, or additional payment to cover administrative costs. The Company further reserves the right to ask any customer to provide sufficient
                    documentation for The Company to be satisfied in our absolute discretion as to the customer's identity prior to The Company crediting any bonus, free
                    bet, risk-free bet, or offer to their account.
                    <br/>
                    5.4. All our site offers are intended for recreational players and our site may in its sole discretion limit the eligibility of customers to
                    participate in all or part of any promotion.
                    <br/>
                    5.5. Our site reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.
                    <br/>
                    5.6. Bonuses can only be received once per person/account, family, household, address, phone number, IP addresses, and environments where computers
                    are shared (university, fraternity, school, public library, workplace, etc.). The Company reserves the right to close your account and confiscate any
                    existing funds if evidence of abuse/fraud is found.
                    <br/>
                    5.7. You acknowledge and understand that separate User Agreements exist with respect to promotions, bonuses, and special offers, and are in addition
                    to these User Agreements. These User Agreements are set forth on the respective content page on this website, or have been made available to you
                    personally, as the case may be. In the event of a conflict between the provisions of such promotions, bonuses, and special offers, and the provisions
                    of these User Agreements, the provisions of such promotions, bonuses, and special offers will prevail.
                    <br/>
                    5.8. The Company may insist that you bet a certain amount of your own deposit before you can bet with any free/bonus funds we credit to your account.
                    <br/>
                    5.9. You accept that certain promotions may be subject to Withdraw restrictions and/or requirements which need to be met before funds credited under
                    the promotion can be withdrawn. Such terms shall be duly published and made available as part of the promotion. If you opt to make a Withdrawal before
                    the applicable wagering requirements are fulfilled, we will deduct the whole bonus amount as well as any winnings connected with the use of the bonus
                    amounts before approving any Withdrawal.
                    <br/>

                    6. LIVE CHAT
                    <br/>
                    6.1. As part of your use of the Website, we may provide you with a live chat facility, which is moderated by The Company and subject to controls. The
                    Company reserves the right to review the chat and to keep a record of all statements made on the facility. Your use of the chat facility should be for
                    recreational and socializing purposes.
                    <br/>
                    6.2. The Company has the right to remove the chat room functionality or immediately terminate your Wallet and refund your account balance if you:
                    <br/>
                    (a) make any statements that are sexually explicit or grossly offensive, including expressions of bigotry, racism, hatred, or profanity;
                    <br/>
                    (b) make statements that are abusive, defamatory, or harassing or insulting;
                    <br/>
                    (c) use the chat facility to advertise, promote, or otherwise relate to any other online entities;
                    <br/>
                    (d) make statements about our site, or any other Internet site(s) connected to the Website that are untrue and/or malicious and/or damaging to our
                    site;
                    <br/>
                    (e) use the chat facility to collude, engage in unlawful conduct, or encourage conduct we deem seriously inappropriate. Any suspicious chats will be
                    reported to the competent authority.
                    <br/>
                    6.3. Live Chat is used as a form of communication between The Company and you and should not be copied or shared with any forums or third parties.
                    <br/>

                    7. LIMITATION OF LIABILITY
                    <br/>
                    7.1. You enter the Website and participate in the Games at your own risk. The Websites and the Games are provided without any warranty whatsoever,
                    whether expressed or implied.
                    <br/>
                    7.2. Without prejudice to the generality of the preceding provision, we, our directors, employees, partners, service providers:
                    <br/>
                    7.3. Do not warrant that the software, Games, and the Websites are fit for their purpose;
                    <br/>
                    7.4. Do not warrant that the software, Games, and the Websites are free from errors;
                    <br/>
                    7.5. Do not warrant that the software, Games, and the Websites will be accessible without interruptions;
                    <br/>
                    7.6. Shall not be liable for any loss, costs, expenses, or damages, whether direct, indirect, special, consequential, incidental, or otherwise,
                    arising in relation to your use of the Websites or your participation in the Games.
                    <br/>
                    7.7. You understand and acknowledge that, if there is a malfunction in a Game or its interoperability, any bets made during such a malfunction shall
                    be void. Funds obtained from a malfunctioning Game shall be considered void, as well as any subsequent game rounds with said funds, regardless of what
                    Games are played using such funds.
                    <br/>
                    7.8. You hereby agree to fully indemnify and hold harmless us, our directors, employees, partners, and service providers for any cost, expense, loss,
                    damages, claims, and liabilities howsoever caused that may arise in relation to your use of the Website or participation in the Games.
                    <br/>
                    7.9. To the extent permitted by law, our maximum liability arising out of or in connection with your use of the Websites, regardless of the cause of
                    actions (whether in contract, tort, breach of warranty, or otherwise), will not exceed KES 10,000.
                    <br/>

                    8. BREACHES, PENALTIES AND TERMINATION
                    <br/>
                    8.1. If you breach any provision of these User Agreements or we have reasonable grounds to suspect that you have breached them, we reserve the right
                    to not open, to suspend, or to close your account, or withhold payment of your winnings and apply such funds to any damages due by you.
                </Box>
            </Drawer>
        </div>
    );
}