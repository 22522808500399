import React from 'react';
import {Drawer} from "@mui/material";
import Refer from "./Refer";
import {BORDER_RADIUS, REFERRAL_BANNER} from "../../../Helper";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function ReferDrawer() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <div>
            <img onClick={toggleDrawer(true)} className={'my-1'} style={{width: '100%', borderRadius: BORDER_RADIUS}} src={REFERRAL_BANNER}
                 alt={'refer and earn'}/>

            <Drawer PaperProps={{sx: {height: 'auto', background: 'transparent', marginTop: '30px'}}} anchor={'right'} open={open} onClose={toggleDrawer(false)}>

                <div className={'container-fluid'}>
                    <div className={'row'}>
                        <div style={{textAlign: 'right'}} className={'col'}>
                            <HighlightOffIcon onClick={toggleDrawer(false)} fontSize={'large'} sx={{color: '#FFFFFF'}}/>
                        </div>
                    </div>
                </div>

                <Refer/>
            </Drawer>
        </div>
    );
}